<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data Sikronisasi Anggaran </strong><small>Edit Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="updateAnggaran">
              <CRow>
              <CCol sm="12">             
                <div class="form-group">
                <label>Akun Sakti</label>
                  <select class="form-control" v-model="nama_upt">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option v-for="r in upts" :value="r.id_upt">{{r.nama_upt}} </option>
                  </select>
                </div>
              </CCol> 
              <CCol sm="6">
                <CInput
                  v-model="tahun_realisasi"
                  label="Tahun Realisasi"
                  placeholder="Input Tahun Realisasi"
                />
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="nilai_anggaran"
                  type="number"
                  label="Nilai Anggaran"
                  placeholder="Input Nilai Anggaran"
                />
              </CCol>
                      
            </CRow>      
              <div class="form-actions">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/anggaran">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>    
  
</template>

<script>
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  data () {
    return {
      nama_upt : "",
      tahun_realisasi : "",
      nilai_anggaran : "",
      selected: [], // Must be an array reference!
      upts: [],
      show: true,
      horizontal: { label:'col-3', input:'col-9' },
      options: ['Option 1', 'Option 2', 'Option 3'],
      jenis: ['Penyelenggaran Jalan Kabupaten/Kota'],
      subjenis: ['Pembangunan dan Peningkatan Jalan', 'Rekonstruksi / Pemeliharaan / Rehabilitasi Jalan', 'Pembangunan Jembatan / Box Culvert'],
      tahuns: ['2021','2020'],
      selectOptions: [
        'Option 1', 'Option 2', 'Option 3',
        { 
          value: 'some value', 
          label: 'Selected option'
        }
      ],
      selectedOption: 'some value',

      formCollapsed: true,
      checkboxNames: ['Checkboxes', 'Inline Checkboxes',
                      'Checkboxes - custom', 'Inline Checkboxes - custom'],
      radioNames: ['Radios', 'Inline Radios',
                    'Radios - custom', 'Inline Radios - custom']
    }
  },
  mounted(){
  this.loadData();
  this.showAnggaran();
  },

  methods: {
    
    loadData: function(event){
      
      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"upts")
        .then((response) => {
              this.upts=response.data;
        })
    },

    updateAnggaran: function(event){

      const anggaran = { 
                          id_upt: this.nama_upt, 
                          tahun_realisasi: this.tahun_realisasi, 
                          nilai_anggaran: this.nilai_anggaran,
                          id_anggaran: this.$route.params.id_anggaran
                          };

      alert(JSON.stringify(anggaran));
      axios.put(process.env.VUE_APP_BASE_URL+"anggaran/"+this.$route.params.id_anggaran, anggaran)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/anggaran');
            }
            else{
              this.modalError = true;
            }            
        })
    },
    showAnggaran: function(event){
    
      const anggaran = { 
                          nama_upt: this.nama_upt, 
                          tahun_realisasi: this.tahun_realisasi,
                          nilai_anggaran: this.nilai_anggaran
                          };
      // alert(JSON.stringify(pekerjaan));
      // alert(this.$route.params.id_User);

      let username =window.btoa(this.$route.params.id_user);
      console.log(username);

      axios.get(process.env.VUE_APP_BASE_URL+"anggaran/"+this.$route.params.id_anggaran)
        .then((response) => {            
              this.nilai_anggaran=response.data.nilai_anggaran;
              this.tahun_realisasi=response.data.tahun_realisasi;
              this.nama_upt=response.data.id_upt;
        })
    }
  }
}
</script>